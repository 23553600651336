<template>
  <el-tabs v-model="activeName">
    <el-tab-pane :label="translateTitle('发布短文')" name="1">
      <ShortEssay v-if="activeName == 1" @handleTabActive="handleTabActive" />
    </el-tab-pane>
    <!-- <el-tab-pane :label="translateTitle('发布长文')" name="2">
    </el-tab-pane>
    <el-tab-pane :label="translateTitle('发布文章')" name="3">
    </el-tab-pane> -->
  </el-tabs>
</template>

<script>
  import ShortEssay from './components/shortEssay'
  import { translateTitle } from '@/utils/i18n'
  export default {
    components: {
      ShortEssay,
    },
    data() {
      return {
        activeName: '1',
      }
    },
    methods: {
      translateTitle,
      handleTabActive(active) {
        this.activeName = active
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-tabs {
    ::v-deep .el-tabs__header {
      margin-bottom: 0;
      .el-tabs__nav-scroll {
        display: flex;
        justify-content: flex-end;
        padding: 0 20px;
      }
      .el-tabs__item {
        height: 60px;
        line-height: 60px;
      }
    }
  }
</style>
