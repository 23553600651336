<template>
  <div class="short-essay-container">
    <Form
      :column="column"
      :query="query"
      @handleImageUrl="handleImageUrl"
      @handleVideoUrl="handleVideoUrl"
    >
      <template #user>
        <div class="short-essay-user" @click="handleVestList">
          <img alt="" :src="userInfo.head" />
          <span>{{ userInfo.name }}</span>
          <b>点击切换用户</b>
        </div>
      </template>
    </Form>
    <el-divider />
    <div class="create-push-btn">
      <el-button size="medium" type="primary" @click="handleRelease('now')">
        {{ translateTitle('发布') }}
      </el-button>
      <el-button
        icon="el-icon-pie-chart"
        size="medium"
        type="primary"
        @click="hanldeTiming"
      >
        {{ translateTitle('定时发布') }}
      </el-button>
    </div>
    <!-- 定时发布--对话框 -->
    <ReleaseDialog ref="releaseDialog" @handleRelease="handleRelease" />
    <!-- 选择用户--对话框 -->
    <VestList ref="vestList" @setUserInfo="setUserInfo" />
  </div>
</template>

<script>
  import Form from '@/components/Form'
  import ReleaseDialog from '@/components/ReleaseDialog'
  import VestList from '../../components/vestList'
  import { getShowListPage, getAppInfo, contentAdd } from '@/api/audit'
  import { translateTitle } from '@/utils/i18n'
  export default {
    components: {
      Form,
      ReleaseDialog,
      VestList,
    },
    data() {
      return {
        query: {
          vest_user_id: '',
          content: '',
          pics: '',
          video: '',
          create_time: 0,
          circle_id: '',
          client_type: 4,
          file_type: -1,
        },
        userInfo: {},
        showList: [],
        typeList: Object.freeze([
          {
            id: -1,
            label: '无',
          },
          {
            id: 1,
            label: '图片',
          },
          {
            id: 2,
            label: '视频',
          },
        ]),
      }
    },
    computed: {
      column() {
        return [
          {
            label: '发布用户',
            prop: 'vest_user_id',
            type: 'slot',
            slotName: 'user',
          },
          {
            label: '选择圈子',
            type: 'select',
            prop: 'circle_id',
            optionLabel: 'name',
            list: this.showList,
          },
          {
            label: '标题',
            prop: 'content',
            type: 'textarea',
            tips: '建议标题字数在25字以内',
          },
          {
            label: '附件类型',
            prop: 'file_type',
            type: 'radio',
            list: this.typeList,
          },
          {
            label: '列表图片',
            prop: 'pics',
            type: 'uploadImage',
            eventName: 'handleImageUrl',
            isHide: this.query.file_type != 1,
            tips: '上传图片',
          },
          {
            label: '视频',
            prop: 'video',
            type: 'uploadVideo',
            eventName: 'handleVideoUrl',
            isHide: this.query.file_type != 2,
            tips: '上传视频',
          },
        ]
      },
    },
    created() {
      this.getAppInfo()
      this.getShowListPage()
    },
    methods: {
      translateTitle,
      // 获取用户信息
      async getAppInfo() {
        const res = await getAppInfo()
        if (res.success) {
          this.userInfo = res.data.user
          this.query.vest_user_id = res.data.user.user_id
        }
      },
      // 获取圈子
      async getShowListPage(val) {
        const params = {
          name: val || '',
          page: 1,
          step: 20,
        }
        const res = await getShowListPage(params)
        if (res.success && res.list.length) {
          this.showList = res.list
        }
      },
      // 获取元宇宙用户
      handleVestList() {
        this.$refs.vestList.handleOpen()
      },
      // 打开定时发布时间
      hanldeTiming() {
        this.$refs.releaseDialog.handleOpen()
      },
      setUserInfo(val) {
        this.userInfo = val
        this.query.vest_user_id = val.user_id
      },
      // 获取图片地址
      handleImageUrl(prop, data) {
        this.query[prop] = data
      },
      // 获取视频上传地址
      handleVideoUrl(prop, query) {
        this.query[prop] = query.video_url
        this.query['video_aid'] = query.video_aid
        this.query['pics'] = query.pic_aid
      },
      // 发布
      async handleRelease(type, value) {
        if (!this.query.content) {
          return this.$message.error('标题不能为空')
        }
        const params = Object.assign({}, this.query)
        if (type === 'now') {
          params.create_time = 0
        } else if (type === 'timing') {
          params.create_time = value / 1000
        }
        if (!params.pics || params.file_type === -1) delete params.pics
        if (!params.video || params.file_type !== 2) delete params.video
        delete params.file_type
        const res = await contentAdd(params)
        if (res.success) {
          this.$router.push('/audit/circleTheme')
        } else {
          this.$message.error(res.msg)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.short-essay';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 65px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
    #{$base}-btn {
      margin: 40px 0 80px 100px;
    }
    .el-select {
      width: 300px !important;
    }
    #{$base}-user {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 50px;
        height: 50px;
        border-radius: 25px;
      }
      span {
        margin: 0 10px;
      }
      b {
        color: $base-color-blue;
      }
    }
  }
</style>
