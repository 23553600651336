var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "short-essay-container" },
    [
      _c("Form", {
        attrs: { column: _vm.column, query: _vm.query },
        on: {
          handleImageUrl: _vm.handleImageUrl,
          handleVideoUrl: _vm.handleVideoUrl,
        },
        scopedSlots: _vm._u([
          {
            key: "user",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "short-essay-user",
                    on: { click: _vm.handleVestList },
                  },
                  [
                    _c("img", { attrs: { alt: "", src: _vm.userInfo.head } }),
                    _c("span", [_vm._v(_vm._s(_vm.userInfo.name))]),
                    _c("b", [_vm._v("点击切换用户")]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "create-push-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "medium", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleRelease("now")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.translateTitle("发布")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-pie-chart",
                size: "medium",
                type: "primary",
              },
              on: { click: _vm.hanldeTiming },
            },
            [_vm._v(" " + _vm._s(_vm.translateTitle("定时发布")) + " ")]
          ),
        ],
        1
      ),
      _c("ReleaseDialog", {
        ref: "releaseDialog",
        on: { handleRelease: _vm.handleRelease },
      }),
      _c("VestList", { ref: "vestList", on: { setUserInfo: _vm.setUserInfo } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }